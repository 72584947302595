<script setup>
import { ref, onMounted, onUnmounted } from 'vue'

onMounted(() => {
  // 初始化逻辑
  window.scrollTo(0, 0)
})

onUnmounted(() => {
  // 组件卸载时的清理工作
})
</script>

<template>
  <div
    class="PrivacyPolicyView"
    style="padding: 10px 15px 60px 15px; font-size: 14px; line-height: 24px"
  >
    <h1 style="text-align: center; padding: 10px 0 15px 0">隐私政策</h1>
    <p style="text-indent: 2em">
      您的信任对我们非常重要，我们深知个人信息对您的重要性，我们将按法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。有鉴于此，成都星朗乐科技有限公司（以下简称“我们”或“牧星查”）作为牧星查产品及服务的提供者制定本《隐私政策》（下称“本政策”）并提醒您：
    </p>
    <br />
    <p style="text-indent: 2em">
      本政策适用于全部牧星查产品及服务，如我们关联公司的产品或服务中使用了牧星查提供的产品或服务但未设独立的隐私政策的，该部分牧星查提供的产品或服务同样适用于本政策。
    </p>
    <br />
    <p style="text-indent: 2em">
      需要特别说明的是，本政策不适用于其他第三方通过网页或牧星查客户端直接向您提供的服务（统称“第三方服务”），您向该第三方服务提供者提供的信息不适用于本政策，您在选择使用第三服务前应充分了解第三方服务的产品功能及隐私保护政策，再选择是否开通功能。
    </p>
    <br />
    <p style="text-indent: 2em">
      在使用牧星查产品或服务前，请您务必仔细阅读并透彻理解本政策，在确认充分理解使用相关产品或服务。一旦您开始使用牧星查产品或服务，即表示您已充分理解并同意本政策。
    </p>
    <br />
    <p style="text-indent: 2em">第一部分 定义</p>
    <br />
    <p style="text-indent: 2em">
      1、牧星查服务提供者：是指研发并提供牧星查产品和服务法律主体，成都星朗乐科技有限公司（下称“我们”或“牧星查”）
    </p>
    <p style="text-indent: 2em">2、牧星查用户：是指注册牧星查账户的用户，以下称“您”。</p>
    <p style="text-indent: 2em">
      3、个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
    </p>
    <p style="text-indent: 2em">
      4、个人信息删除：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态，具体指产品内的账号注销功能。
    </p>
    <p style="text-indent: 2em">
      5、个人信息匿名化：通过对个人信息的加密技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。
    </p>
    <br />
    <p style="text-indent: 2em">第二部分 隐私政策</p>
    <br />
    <p style="text-indent: 2em">一、我们如何收集您的个人信息</p>
    <p style="text-indent: 2em">
      为了向您及牧星查企业用户提供牧星查服务，维护牧星查服务的正常运行，改进及优化我们的服务体验并保障您的账号安全，我们会出于本政策下述目的及方式收集您在注册、使用牧星查服务时主动提供、授权提供或基于您使用牧星查服务时产生的信息：
    </p>
    <p style="text-indent: 2em">（一）注册牧星查用户信息</p>
    <p style="text-indent: 2em">
      为注册成为牧星查用户，以便我们为您提供牧星查服务，诸如数据查询、视频查看等功能，您需要提供您的手机号码及短信验证码以注册并创建牧星查账号，否则您将不能使用牧星查服务。
    </p>
    <p style="text-indent: 2em">
      如果您仅需使用浏览、搜索牧星查网页展示的产品、功能及服务介绍，您不需要注册成为牧星查用户并提供上述信息。
    </p>
    <p style="text-indent: 2em">
      如您的账号是注册在企业下的关联账号，当您所在企业用户注销牧星查账户时，我们将会匿名化处理或删除您在该组织的相关个人信息，但您作为牧星查个人用户的个人信息仍将保留，除非您主动注销牧星查账户。
    </p>
    <p style="text-indent: 2em">（二）使用牧星查服务过程中收集信息</p>
    <p style="text-indent: 2em">
      当您在使用牧星查服务过程中，为向您提供更契合您需求的牧星查软件服务、交互展示、搜索结果、识别账号异常状态，维护牧星查服务的正常运行，改进及优化您对牧星查服务的体验并保障您的账号安全，包括您使用牧星查服务以及使用方式的信息，并将这些信息进行关联：
    </p>
    <p style="text-indent: 2em">1、使用信息</p>
    <p style="text-indent: 2em">
      在提供服务过程中，为了保证您能正常使用产品功能，我们可能会向系统申请您的设备权限，可能包括但不限于IMEI、IMSI、设备MAC地址、软件列表、设备序列号、android
      ID等。申请前我们会通过弹窗或页面说明的方式征询您的同意，你可以选择“允许”或“禁止”权限申请。因少量设备厂商在应用安装时就默认您给予了部分基础权限，不支持弹窗确认功能，具体我们建议您关注您的设备功能说明及权限列表，如您不同意给我们这些权限的，您可以及时通过设备中设置的路径取消对我们的授权；支持弹窗功能的设备权限申请成功后，您可以随时进入设备“设置-权限管理”中关闭相应权限，权限关闭后相关产品功能可能无法正常使用。在不同设备中，权限显示方式及关闭方式可能有所不同，具体请您参考设备及系统开发方说明或指引。
    </p>
    <p style="text-indent: 2em">提供服务过程中主要涉及的访问权限如下：</p>
    <p style="text-indent: 2em">
      基于设备相机及相册权限的附加功能：出于您的使用需求，当您需要在使用在线客服的相机、相册时，我们需要取得您的设备相机或相册权限，并收集您提供的图文和/或视频信息。如您拒绝提供仅会使您无法在相关功能中添加图片和/或视频，但并不影响您正常使用其他功能。
    </p>
    <p style="text-indent: 2em">
      基于设备麦克风权限的附加功能：若您需要在使用客服电话服务时，我们需要取得您的设备麦克风权限，并收集您的语音信息。如您拒绝提供仅会使您无法使用相关语音功能，但并不影响您正常使用其他功能。
    </p>
    <p style="text-indent: 2em">
      您理解并同意，前述涉及设备权限（相机（摄像头）、相册、麦克风、通讯录、）的授权需要您在您的设备中打开相关访问权限，以实现这些权限所涉及信息的收集与使用。您亦可以在您的设备中查看或调整前述权限状态。您了解并知悉，一旦您开启权限则表示您授权我们可以收集和使用相关个人信息来为您提供相关服务；一旦您关闭该权限则表示您取消了授权，我们将不再基于该权限继续收集和使用相关个人信息，也无法为您提供对应的服务与功能。但是，您的关闭行为并不会影响此前基于您的授权行为所进行的信息收集与使用。
    </p>
    <p style="text-indent: 2em; padding: 10px 0">2、设备信息</p>
    <p style="text-indent: 2em">
      我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如设备型号、操作系统版本、设备设置、唯一设备标识符等软硬件特征信息）、设备所在位置相关信息（例如IP地址、GPS/北斗位置信息以及能够提供相关信息的Wi-Fi接入点、蓝牙和基站等传感器信息）。
    </p>
    <p style="text-indent: 2em; padding: 10px 0">3、日志信息</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的搜索查询内容、IP地址、使用的语言、访问日期和时间、您访问的网页记录、日志信息。
    </p>
    <p style="text-indent: 2em">
      请注意，单独的设备信息、日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将有可能被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。
    </p>
    <p style="text-indent: 2em; padding: 10px 0">4、您向我们提供的信息</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      在服务使用过程中，您可以对牧星查产品及服务的体验问题反馈，帮助我们更好地了解您使用我们产品或服务的体验和需求，改善我们产品或服务,为此我们会记录您的联系信息、反馈的问题或建议，以便我们进一步联系您反馈您我们的处理意见。
    </p>
    <p style="text-indent: 2em">
      为向您提供更好、更个性化的服务，例如在不同的服务端或设备上提供更个性化但体验一致的服务，更契合您需求的客服接待或信息推送，了解产品适配性，识别账号异常状态。
    </p>
    <p style="text-indent: 2em; padding: 10px 0">5、第三方软件开发包（SDK）</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      我们产品中可能会包含第三方SDK或其他类似的应用程序，如您在我们平台上使用这类由第三方提供的服务时，您同意将由其直接收集和处理您的信息（如以嵌入代码、插件等形式），例如：当您使用支付宝账号授权我们的产品时，支付宝SDK需要收集您的账号信息。前述服务商收集和处理信息等行为遵守其自身的隐私条款，而不适用于本政策。但我们也会努力审查该第三方的业务准入资质并努力要求该服务商的合法合规性与安全性。为了最大程度保障您的信息安全，我们强烈建议您在使用任何第三方SDK类服务前先行查看其隐私条款。为保障您的合法权益，如您发现这等SDK或其他类似的应用程序存在风险时，建议您立即终止相关操作并及时与我们取得联系；
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      以下是我们目前接入的第三方SDK类服务商的信息：
    </p>
    <h2 style="text-align: center; margin: 20px 0">权限清单</h2>
    <!-- 权限名称	权限功能说明	使用场景或目的	使用平台(iOS/Android)
读取/写入外置存储	读取和写入设备储存空间内的数据	用于保障牧星查的稳定运行，以便用户在使用牧星查的功能时可读取、写入/下载/保存/修改图片、文件、崩溃日志等信息	Android、iOS
摄像头	使用摄像头拍摄图片	用于完成照片的拍摄和发布	Android、iOS -->
    <table>
      <thead>
        <tr>
          <th>权限名称</th>
          <th>权限功能说明</th>
          <th>使用场景或目的</th>
          <th>使用平台(iOS/Android)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>读取/写入外置存储</td>
          <td>读取和写入设备储存空间内的数据</td>
          <td>
            用于保障牧星查的稳定运行，以便用户在使用牧星查的功能时可读取、写入/下载/保存/修改图片、文件、崩溃日志等信息
          </td>
          <td>Android、iOS</td>
        </tr>
        <tr>
          <td>摄像头</td>
          <td>使用摄像头拍摄图片</td>
          <td>用于完成照片的拍摄和发布</td>
          <td>Android、iOS</td>
        </tr>
      </tbody>
    </table>
    <h2 style="text-align: center; margin: 20px 0">Android操作系统第三方SDK列表</h2>
    <table>
      <thead>
        <tr>
          <th>第三方SDK名称</th>
          <th>应用场景</th>
          <th>收集个人信息的类型</th>
          <th>第三方SDK提供方</th>
          <th>隐私政策链接</th>
          <th>SDK收集使用个人信息的使用目的</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>支付宝SDK</td>
          <td>提供支付宝支付、提现能力</td>
          <td>网络状态信息、设备信息、本机号码</td>
          <td>支付宝</td>
          <td><a href="https://render.alipay.com/p/c/k2cx0tg8">隐私政策链接</a></td>
          <td>提供支付宝支付、提现能力</td>
        </tr>
        <tr>
          <td>支付宝人身核验SDK</td>
          <td>人脸识别</td>
          <td>人脸识别</td>
          <td>支付宝</td>
          <td><a href="https://render.alipay.com/p/c/k2cx0tg8">隐私政策链接</a></td>
          <td>确保本人操作</td>
        </tr>
        <tr>
          <td>保存本地海报</td>
          <td>使用手机相册存储和相机</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <td>微信</td>
          <td>打开用户微信分享信息给用户或分享至朋友圈</td>
          <td>-</td>
          <td>深圳市腾讯计算机系统有限公司</td>
          <td><a href="https://weixin.qq.com/agreement?lang=zh_CN">隐私政策链接</a></td>
          <td>-</td>
        </tr>
      </tbody>
    </table>

    <h2 style="text-align: center; margin: 20px 0">IOS操作系统第三方SDK列表</h2>
    <table>
      <thead>
        <tr>
          <th>第三方SDK名称</th>
          <th>应用场景</th>
          <th>收集个人信息的类型</th>
          <th>第三方SDK提供方</th>
          <th>隐私政策链接</th>
          <th>SDK收集使用个人信息的使用目的</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>支付宝SDK</td>
          <td>提供支付宝支付、提现能力</td>
          <td>网络状态信息、设备信息、本机号码</td>
          <td>支付宝</td>
          <td><a href="https://render.alipay.com/p/c/k2cx0tg8">隐私政策链接</a></td>
          <td>-</td>
        </tr>
        <tr>
          <td>支付宝人身核验SDK</td>
          <td>人脸识别</td>
          <td>人脸识别</td>
          <td>支付宝</td>
          <td><a href="https://render.alipay.com/p/c/k2cx0tg8">隐私政策链接</a></td>
          <td>确保本人操作</td>
        </tr>
        <tr>
          <td>保存本地海报</td>
          <td>使用手机相册存储和相机</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <td>微信</td>
          <td>打开用户微信分享信息给用户或分享至朋友圈</td>
          <td>-</td>
          <td>深圳市腾讯计算机系统有限公司</td>
          <td><a href="https://weixin.qq.com/agreement?lang=zh_CN">隐私政策链接</a></td>
          <td>-</td>
        </tr>
      </tbody>
    </table>
    <p style="text-indent: 2em; padding: 10px 0">6、为您提供安全保障收集信息</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      为预防、发现、调查欺诈、侵权、危害安全、非法或违反与我们或与我们关联公司的协议、政策或规则的行为，我们可能收集或整合您的用户个人信息、服务使用信息、设备信息、日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      您理解并同意，我们向您提供的功能和服务场景是不断迭代升级的，如我们未在上述场景中明示您需要收集的个人信息，我们将会通过页面提示、交互设计等方式另行向您明示信息收集的内容、范围和目的并征得您同意。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      如我们停止运营牧星查产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以公告或短信的形式通知您，并依照所适用的法律对所持有的您的个人信息进行删除或匿名化处理。
    </p>
    <p style="text-indent: 2em; padding: 10px 0">二、我们如何使用信息</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      收集您的信息是为了向您提供服务及提升服务质量，为了实现这一目的，我们会把您的信息用于下列用途：
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （1）向您提供您使用的牧星查产品或服务，并维护、改进、优化这些服务及服务体验；
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （2）为预防、发现、调查欺诈、侵权、危害安全、非法或违反与我们或与我们关联公司的协议、政策或规则的行为，保护您、其他用户或公众以及我们或我们关联公司的合法权益，我们会使用或整合您的个人信息、服务使用信息、设备信息、日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您的操作风险、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施；
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">（3）经您许可的其他用途。</p>
    <p style="text-indent: 2em; padding: 10px 0">三、我们如何使用Cookie 和同类技术</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      为使您获得更轻松的访问体验，您使用牧星查产品或服务时，我们可能会通过采用各种技术收集和存储您访问牧星查服务的相关数据，在您访问或再次访问牧星查服务时,我们能识别您的身份，并通过分析数据为您提供更好更多的服务。包括使用小型数据文件识别您的身份，这么做是为了解您的使用习惯，帮您省去重复输入账户信息的步骤，或者帮助判断您的账户安全。这些数据文件可能是Cookie、Flash
      Cookie，或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      请您理解，我们的某些服务只能通过使用Cookie才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝牧星查的Cookie，但拒绝牧星查的Cookie在某些情况下您可能无法使用依赖于cookies的牧星查服务的部分功能。
    </p>
    <p style="text-indent: 2em; padding: 10px 0">四、我们如何共享、转让、公开披露您的信息</p>
    <p style="text-indent: 2em; margin-bottom: 10px">(一) 共享</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      我们不会和其他公司、组织和个人共享您的个人信息，但以下情况除外：
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （1）在获取您同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （2）在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （3）只有透露您的资料，才能提供您所要求的第三方产品和服务，在您通过牧星查客户端购买查询服务的，您同意牧星查向实际产品提供者提供您的身份信息，包括真实姓名和身份证号等。为了提升实人认证的准确性，您同意第三方公司仅限于个人信息进行验证相关服务，将您提供的个人信息与法律法规允许的机构或政府机关授权的机构的数据进行校验。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （4）在您被他人投诉侵犯知识产权或其他合法权利时，需要向投诉人披露您的必要资料，以便进行投诉处理的；
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （5）牧星查服务可能含有其他网站的链接。除法律另有规定外，牧星查对其他网站的隐私保护措施不负相应法律责任。我们可能在需要的时候增加商业伙伴，但是提供给他们的将仅是综合信息，我们将不会公开您的个人信息。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">(二) 转让</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （1）在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （2）在牧星查发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      (三) 公开披露 我们仅会在以下情况下，公开披露您的个人信息：
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （1）获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （2）如果我们确定您出现违反法律法规或严重违反牧星查相关协议规则的情况，或为保护牧星查及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或牧星查相关协议规则征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及牧星查已对您采取的措施。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      (四) 共享、转让、公开披露个人信息时事先征得授权同意的例外
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">（1）与国家安全、国防安全有关的；</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （2）与公共安全、公共卫生、重大公共利益有关的；
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （3）与犯罪侦查、起诉、审判和判决执行等有关的；
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">（5）您自行向社会公众公开的个人信息；</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      请您注意，根据法律规定，共享、转让经匿名化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">五、我们如何保护您的信息</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      我们会采取各种预防措施来保护您的个人信息，以保障您的个人信息免遭丢失、盗用和误用，以及被擅自取阅、披露、更改或销毁。为确保您个人信息的安全，我们有严格的信息安全规定和流程并严格执行上述措施。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      牧星查建立了全方位、多维度的数据安全管理体系，保证整个牧星查各个平台的安全性。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      我们会采取合理可行的措施，尽力避免收集无关的个人信息，并在限于达成本政策所述目的所需的期限以及所适用法律法规所要求的期限内对你的个人信息进行脱敏处理。在您使用查询过程中所涉及的用户姓名、身份证号、手机号/账号密码信息均采用的是MD5加密方式，所有二次输出信息均经过脱敏处理，数据库文件不存储用户明文数据。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      在不幸发生个人信息安全事件后，我们将按照法律法规的要求（最迟不迟于30
      个自然日内）向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等其一方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      互联网环境并非百分之百安全，尽管我们有这些安全措施，但仍然无法完全避免互联网中存在的各种风险，我们将尽力确保您的信息的安全性。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">六、未成年人保护</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      我们重视未成年人的信息保护，如您为未成年人的，建议您请您的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许，父母或监护人明确同意或者保护未成年人所必要的情况下使用，共享，转让或披露此信息。我们将根据国家相关法律法规及本政策的规定保护未成年人的个人信息。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">七、您的个人信息存储</p>
    <p style="text-indent: 2em; margin-bottom: 10px">（一）存储地区</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      我们将在中华人民共和国境内运营牧星查服务中收集和产生的个人信息存储在中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，我们将会遵循相关国家规定或者征求您的同意。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">（二）存储期限</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      您在使用本平台期间，我们将保存您的个人脱敏加密信息，保存期限将以不超过为您提供服务所必须的期间为原则。在您终止使用本平台后，除法律法规对于特定信息保留期限另有规定外，我们会对您的信息进行删除或做匿名化处理。如我们停止运营本平台服务，我们将在合理期限内依照所适用的法律对所持有的您的个人信息进行删除或匿名化处理。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">八、您享有的权利及权利行使路径</p>
    <p style="text-indent: 2em; margin-bottom: 10px">（一）访问查询权</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      您对您的牧星查账号内的信息（含个人信息）依法享有访问查询权，包括：
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      账户信息：您可以登录手机客户端，通过【我的】可以访问您的姓名、绑定手机号。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      使用信息：您可以在牧星查手机客户端相关页面访问、查询您的使用信息，包括订单信息，可以通过【报告列表-查看详情】进行访问、查看。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      其他信息：如您在此前述过程中遇到操作问题的或如需获取其他前述无法获知的个人信息内容，您可通过在线客服或邮箱联系我们，我们将在核实您的身份后在合理期限内向您提供，但法律法规另有规定的或本政策另有约定的除外。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">（二）注销权</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      对于您的账户信息，您可以在牧星查客户端相关功能页面根据操作指引自行注销。您了解并知悉，一旦您进行相关操作，我们将进行账户预注销，与您的账户相关的数据也将同步删除，并将久永不能恢复，若您在180天内未进行登录，您的账户有可能被冻结，你需要重新激活方可使用。对已经被冻结且长时间未激活的用户账号，我们将进行不定期的排查，你的账号有可能被注销。法律法规另有规定的除外。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      在以下情形中，您可以向我们提出删除个人信息的请求：
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （1）我们违反法规法规的规定收集、使用、处理您的个人信息；
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （2）我们违反与您的约定或未在您同意范围内收集、使用、处理您的个人信息；
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （3）您不再使用牧星查产品/服务，或您主动注销您的牧星查账户；
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">（4）我们无法或不再为您提供任何产品或服务；</p>
    <p style="text-indent: 2em; margin-bottom: 10px">（5）法律法规规定的其他情形。</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      您知悉并同意，一旦您删除或我们协助您注销相关信息，该等注销指令可能无法撤回，该等被注销的信息可能无法恢复，您需谨慎操作并自行做好重要信息的备份。当您注销或我们协助您注销相关信息时，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。更多关于账号注销的流程、条件等事项请详见《账号注销协议》。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      注销指引：下载牧星查APP→登录牧星查账户→（点击）我的→设置（图标）→注销账户→阅读《账号注销协议》→同意注销→注销完成。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">（三）同意的撤回与变更</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      若您需要更改相关权限的授权（例如：相机、相册、麦克风），您可以通过您的硬件设备进行修改。您也可以通过注销牧星查账户的方式永久撤回我们继续收集您个人信息的全部授权。如您在此过程中遇到操作问题的，可以通过本政策“帮助中心”方式联系我们。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （四）帮助反馈权 我们为您提供了多种反馈渠道，具体请见设置—帮助中心。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">（五）提前获知产品与/或服务停止运营权</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      我们将持续为您提供优质服务，若因特殊原因导致我们的部分或全部产品与/或服务被迫停止运营，我们将提前在显著位置或向您发送推送消息或以其他方式通知您，并将停止对您个人信息的收集，同时在超出法律法规规定的必需且最短期限后，我们将会对所持有的您的个人信息进行删除或匿名化处理。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">九、本政策如何更新</p>
    <p style="text-indent: 2em; margin-bottom: 10px">我们的隐私政策可能变更。</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      未经您明确同意我们不会限制您按照本隐私政策所应享有的权利。我们会在牧星查各个平台，包括客户端、相关网页上以首页弹窗形式发布对本隐私政策所做的任何变更，并以交互设计提醒您阅读并完整理解。
      对于重大变更，我们还会提供更为显著的通知（可能包括公告通知甚至向您提供弹窗提示）。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">本政策所指的重大变更包括但不限于：</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （1）我们的服务模式发生重大变化。如处理用户信息的目的、用户信息的使用方式等；
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （2）我们在控制权、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （3）用户信息共享、转让或公开披露的主要对象发生变化；
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （4）我们负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化时；
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （5）用户信息安全影响评估报告表明存在高风险时。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">十、如何联系我们</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      如果您对本政策或数据处理有任何疑问、意见或建议，可以通过牧星查产品内的“关于我们”、“投诉与建议”laichabeiguanfang@normalarmor.com
       与我们联系。我们将在收到您发送的响应请求或相关信息之日起十五（15）天内回复您。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      您理解并同意，当涉及以下任一情形时，我们无法响应您的请求：
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">（1）与国家安全、国防安全有关的；</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （2）与公共安全、公共卫生、重大公共利益有关的；
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">（3）与犯罪侦查、起诉和审判等有关的；</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （4）有充分证据表明您存在主观恶意或滥用权利的；
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">（6）涉及牧星查或任何第三方主体商业秘密的；</p>
    <p style="text-indent: 2em; margin-bottom: 10px">（7）法律法规规定的其他情形。</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向有管辖权的法院提起诉讼来寻求解决方案。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">十一、其他</p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （一）本《隐私政策》的解释及争议解决均应适用中华人民共和国大陆地区法律。与本《隐私政策》相关的任何纠纷，双方应协商友好解决；若不能协商解决，应将争议提交至成都星朗乐科技有限公司注册地有管辖权的人民法院解决。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px">
      （二）本《隐私政策》的标题仅为方便及阅读而设，并不影响正文其中任何规定的含义或解释。
    </p>
    <p style="text-indent: 2em; margin-bottom: 10px; text-align: right;">2024年08月10日</p>
  </div>
</template>

<style lang="less" scoped>
.PrivacyPolicyView {
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }
  thead {
    background-color: #f2f2f2;
  }
}
</style>