<template>
</template>

<script>
    export default {
        name: "HomeView",
        mounted() {
            //根据不同路由跳转不同页面
            if (this.isMobile()) {
                this.$router.replace('/index') // 手机路由
            } else {
                this.$router.replace('/home') // PC路由
            }
        },
        methods: {
            isMobile() {
                return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            }
        }
    }
</script>

<style scoped>
</style>