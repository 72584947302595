<template>
  <div style="font-size: 14px; padding: 0 15px 60px 15px">
    <h1 style="margin: auto; text-align: center; padding: 10px 0 15px 0">用户服务协议</h1>

    <p style="text-indent: 2em">
      本协议是您（以下又称“用户”）在使用本服务时，约定您和成都星朗乐科技有限公司之间权利义务关系的有效协议。
    </p>
    <br />
    <p style="text-indent: 2em">
      在您使用本服务前，请您务必仔细阅读本协议，特别是隐私权保护及授权条款、免除或者限制成都星朗乐科技有限公司责任的条款、争议解决和法律适用条款。一旦您有对本服务的任何部分或全部的注册、查看、定制、使用等任何使用行为，即视为您已充分阅读、理解并接受本协议的全部内容，并与成都星朗乐科技有限公司达成本协议。如您对本协议有任何疑问，应向成都星朗乐科技有限公司客服咨询。如果您不同意本协议的部分或全部约定，您应立即停止使用本服务。
    </p>
    <br />
    <p style="text-indent: 2em">
      您与成都星朗乐科技有限公司达成本协议后，您承诺接受并遵守本协议的约定，并不得以未阅读本协议的内容或者未获得成都星朗乐科技有限公司对您问询的解答等理由，主张本协议无效，或要求撤销本协议。在本协议履行过程中，成都星朗乐科技有限公司可以依其单独判断暂时停止提供、限制或改变本服务，并有权根据自身业务需要修订本协议。一旦本协议的内容发生变动，成都星朗乐科技有限公司将通过平台公布最新的服务协议，不再向您作个别通知。如果您不同意成都星朗乐科技有限公司对本服务协议所做的修改，您应立即停止使用本服务或通过成都星朗乐科技有限公司客服与成都星朗乐科技有限公司联系。如果您继续使用本服务，则视为您接受成都星朗乐科技有限公司对本协议所做的修改，并应遵照修改后的协议执行。
    </p>
    <br />
    <h2>一、服务内容</h2>
    <br />
    本服务向您提供多项个人信息整理服务。您知悉并认可，如您需使用该类服务，必须满足如下所述条件；且您承诺，您向成都星朗乐科技有限公司提请服务申请时，已经满足如下所述条件。<br />
    A.您已注册成为本服务的会员；<br />
    B.您已在服务页面对应框中填写被查询主体的姓名、身份证号、手机号、银行卡号和被查询主体的手机号收到的动态验证码（以下称“被查询主体信息”）；<br />
    C.您确保被查询主体信息是您本人的信息或者被查询主体已授权您本人使用被查询主体信息进行查询（授权内容应包括本条D项所述内容），并且被查询主体已知悉该授权的风险。<br />
    D.被查询主体不可撤销地授权成都星朗乐科技有限公司为查询、评估被查询主体的信息状况：a.可以委托合法存续的第三方机构收集、查询、验证、使用并提供您或被查询主体的个人信息；b.可以向数据源机构采集您或被查询主体的个人信息；c.可以整理、保存、加工、使用您或被查询主体的个人信息，并向您提供数据报告；d.可以向为您提供服务的第三方商户提供脱敏后的个人信息或数据报告。本条所述的个人信息包括但不限于身份信息、联系方式、职业和居住地址等个人基本信息，个人社保、公积金、收入及在商业活动中形成的各类交易记录，个人公共费用缴纳、违法违规信息、财产状况等；<br />
    E.被查询主体已被明确告知提供被查询主体信息并作出D项授权可能给被查询主体带来的各类损失以及其他可能的不利后果，包括采集上述个人信息对被查询主体信用方面可能产生不良影响以及上述信息被信息使用者依法提供给第三方后被他人不当利用的风险。<br />
    F.您已全额支付相应的查询服务费用；<br />
    G.验证码请不要轻易提供给他人，一旦填入手机号对应验证码，视为手机号机主本人操作。
    <br />
    <br />
    <h2>二、服务中断或故障</h2>
    <br />
    您同意，因下列原因导致成都星朗乐科技有限公司技无法正常提供本服务的，成都星朗乐科技有限公司不承担责任：<br />
    （1）承载本服务的系统停机维护期间；<br />
    （2）您的电脑、手机软硬件和通信线路、供电线路出现故障的；<br />
    （3）您操作不当或通过非成都星朗乐科技有限公司授权或认可的方式使用本服务的；<br />
    （4）因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障或政府行为等原因；<br />
    （5）由于黑客攻击、网络供应商技术调整或故障、网站升级、手机运营商系统方面的问题等原因而造成的本服务中断或延迟；<br />
    （6）因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成本服务系统障碍不能执行业务的。
    成都星朗乐科技有限公司不对因使用本服务而对用户造成的间接的、附带的、特殊的、后果性的损失承担任何法律责任；尽管有前款约定成都星朗乐科技有限公司将采取合理行动积极促使本服务恢复正常。<br />
    <br />
    <h2>三、信息的使用和保护</h2>
    <br />
    成成都星朗乐科技有限公司深知您注重个人信息安全和保护，并理解保护被查询主体个人信息的重要性。成都星朗乐科技有限公司会严格遵守中国关于收集、使用、保存用户个人信息的相关法律法规，尽最大努力采用相应安全技术和管理手段保护您或被查询主体的个人信息，防止您或被查询主体个人信息遭受未经授权的访问、适用或泄露、毁损、篡改或者丢失。未经您或被查询主体的授权不会向任何第三方提供。<br />
    但您同意，为核实成都星朗乐科技有限公司的记录、了解用户群体偏好、提高本服务质量、完善本服务的个性化内容及种类、为您提供更优质的服务和用户体验之目的，成都星朗乐科技有限公司可以短信、电子邮件或其他方式向您推送符合您需求的相关资讯；您使用本服务，即表示您已授权成都星朗乐科技有限公司将您相关信息披露给成都星朗乐科技有限公司关联公司（关联公司是指直接或间接控制于本协议一方的任何法律实体，或者与本协议一方共同于另一法律实体的任何法律实体）使用，且成都星朗乐科技有限公司关联公司仅为了向您提供服务而使用您的相关信息。如成都星朗乐科技有限公司关联公司使用您的相关信息，则受本协议约束且会按照与成都星朗乐科技有限公司同等谨慎程度保护您的相关信息。<br />
    成都星朗乐科技有限公司就下列原因导致的您或被查询主体个人信息的泄露，不承担任何法律责任：<br />
    （1）由于您个人原因将本服务的会员账号和密码告知他人或与他人共享成都星朗乐科技有限公司服务账户，由此导致的与您相关的信息的泄露。<br />
    （2）您使用第三方提供的服务（包括您向第三方提供的任何个人信息），须受第三方自己的服务条款及个人信息保护协议（而非本协议）约束，您需要仔细阅读其条款。本协议仅适用于成都星朗乐科技有限公司所提供的服务，并不适用于任何第三方提供的服务或第三方的信息使用规则，成都星朗乐科技有限公司对任何第三方使用由您提供的信息不承担任何责任。<br />
    （3）根据相关的法律法规、相关政府主管部门或相关证券交易所的要求提供、公布与您相关的信息。<br />
    （4）或其他非因成都星朗乐科技有限公司原因导致的与您相关的信息的泄露。<br />
    <br />
    <h2>四.用户声明与保证</h2>
    <br />
    （1）您使用本服务的前提是您依照适用的法律，是具有完全民事权利和民事行为能力，能够独立承担民事责任的自然人。<br />
    （2）您如违反本协议第一条款中的承诺，您可能会对他人造成侵权。如由此给成都星朗乐科技有限公司或他人造成损失的，您需依照法律法规规定承担相应的法律责任。
    <br />
    <br />
    <h2>五、知识产权保护</h2>
    <br />
    本服务涉及的文档资料、软件、商标、图案、排版设计等（以下简称“牧星查”）的著作权、商标以及其他知识产权或权益均为成都星朗乐科技有限公司享有或成都星朗乐科技有限公司获得授权使用。用户不得出租、出借、拷贝、仿冒、复制或修改成牧星查产品任何部分或用于其他任何商业目的，也不得将牧星查产品做反向工程、反编译或反汇编，或以其他方式或工具取得成都星朗乐科技有限公司产品之目标程序或源代码。如果用户违反此约定，造成成都星朗乐科技有限公司及其他任何第三方任何损失的，甲方应予以全额赔偿。
    <br />
    <br />
    <h2>六、违约</h2>
    <br />
    用户不得利用本服务进行任何损害成都星朗乐科技有限公司及其他第三方权益的行为，否则成都星朗乐科技有限公司有权立即终止为该用户提供本服务，并要求用户赔偿损失。由此产生的任何后果由用户自行承担，与成都星朗乐科技有限公司无关。
    <br />
    <br />
    <h2>七、适用法律</h2>
    <br />
    本协议条款的解释、效力及纠纷的解决，适用中华人民共和国大陆地区法律法规。如用户和成都星朗乐科技有限公司之间发生任何争议，首先应友好协商解决，协商不成的，应将争议提交至成都星朗乐科技有限公司注册地有管辖权的人民法院解决。
    <br />
    <br />
    <h2>八、问题咨询</h2>
    <br />
    如您对本协议及本服务有任何问题，请通过“牧星查”公众号联系成都星朗乐科技有限公司进行咨询。<br />
    成都星朗乐科技有限公司会尽最大努力解决您的问题<br />
    <br />
    本协议附件为：<br />
    附件一：大数据查询协议；<br />
    附件二： 信息技术合同；<br />
    附件为本协议不可分割的部分，若附件与协议正文有任何冲突，以协议正文为准。<br />
    <br />
    <h3 style="text-align: center">用户协议-大数据查询协议</h3>
    <br />
    牧星查用户，为了方便您使用牧星查的服务,我们新增加了“大数据查询”业务，请您在使用我们的服务前，仔细阅读本服务协议，(以下简称“本协议”)。一经点击“同意”，则协议成立。为明确您与牧星查的权利和义务，本着平等自愿的原则，双方就相关事宜达成如下协议，牧星查依据本协议为您提供服务。<br />
    第一条 服务事项:<br />
    “牧星查"
    (以下称“我们")，向您提供“大数据查询”服务，以下简称“该服务”。该服务为收费的服务业务，用户使用该服务需要支付一定的费用。<br />
    第二条 您的权利与义务<br />
    1.您在选择“同意用户协议”即已经理解本协议，并与我们达成了协议，您在使用该项服务过程中的具体权利义务由本协议作出规定。如果您不同意本条款中的任何内容，您可以选择不使用我们平台的该项服务；<br />
    2.您必须为年满18岁并具有完全民事行为能力的自然人；<br />
    3.您使用该服务，即是对缴费查询没有任何异议;<br />
    4.本协议包括本协议正文及所有我们网站、APP、
    微信公众号(以下简称:平台)已经发布的或将来可能发布或更新的各类规则，所有规则为本协议不可分割的组成部分，与本协议正文具有同等法律效力。您应当仔细阅读本条款的正文内容及其所属各类规则;<br />
    5.请妥善保管好您个人信息，包括并不限于账号、密码。如因您本人保管个人信息不善，或支付宝信息、密码等出现安全问题，导致他人获得您的个人信息或因此导致个人密码被他人取得可能导致您遭受损失等可能产生的一切后果由您本人承担;<br />
    6.您不得传送任何包含病毒、木马、蠕虫等可能破坏,感染，密码拦截任何系统，数据和信息的程序，不得通过黑客、密码破译等方式违法侵入计算机和网络系统，他人账户。<br />
    <br />
    第三条 牧星查的权利与义务<br />
    1.牧星查为方便您使用增加了"大数据查询”服务，我们是提供该项服务的第三方平台；<br />
    2.我们有权利根据业务需要调整、修改、变更、取消该“大数据查询”服务、该项服务展现方式及该项服务的业务操作流程;<br />
    3.我们有权根据需要不时制订、修改本条款或各类规则，并在我们平台以公示的方式进行公告，不再单独通知您;<br />
    4.
    为维护我们平台的正常运行，保证您查询能及时获得相关结果，我们对于该项服务按照有关该业务公布的时间对外提供服务。<br />
    <br />
    第四条 不可抗力条款<br />
    因下列原因致使“大数据查询功能”不能正常提供服务而可能导致的损失，我们不承担责任:<br />
    1.因台风、地震、海啸、洪水、战争等不可抗力因素，造成互联网不能正常执行业务;<br />
    2.计算机病毒、黑客攻击、网络通信故障等我们不能控制的因素;<br />
    3.为了维护我们平台的正常运行，我们定期或不定期地对系统运行的相关设备进行维护或者检修,因此而造成查询障碍;<br />
    4.服务在合理时间内的中断，我们不承担责任。我们将在合理时间段内通过我们平台公告维护时间。<br />
    <br />
    第五条 协议的变更和终止<br />
    鉴于网络服务的特殊性,我们变更本协议及其附件的若干条款时，将提前通过我们平台公告有关变更事项。修订后的条款或将来可能发布或更新的各类规则-经在我们平台公布后，立即自动生效。如您不同意相关修订，应当立即停止使用该项服务。如您在发布上述协议变更的有关公告后继续使用互联网查询的，视为您已接受协议的有关变更,并受其约束。本协议中的相关条款根据该变更而自动做相应修改，双方无须另行签订书面协议。<br />
    <br />
    第六条 隐私保护<br />
    牧星查保证不对外公开或向第三方提供单个用户的注册资料及存储在牧星查的非公开内容，但下列情况下除外:<br />
    1.事先获得用户的明确授权;<br />
    2.根据有关的法律法规要求;<br />
    3.按照有关政府部门的要求;<br />
    4.为维护社会公众的利益;<br />
    5.为维护牧星查的合法利益。<br />
    在不透露单个用户隐私资料的前提下，牧星查有权利对整个用户数据库进行分析并对用户数据库进行商业上的利用。
    <br />
    <br />
    第七条 免责条款<br />
    (一)不管基于任何直接的、间接的、特殊的、惩罚性的、惩戒性的、附带的、或结果性的损害、损失或费用，我们均不对其承担责任。即使有人告知我们或我们的员工存在出现这些损害、损失或费用的可能性。这些损害、损失或费用由以下这些情况引起或与这些情况有关:<br />
    1.使用我们网站上或其他链接网站上的信息;<br />
    2.无法使用这些信息;<br />
    3.
    任何在操作或传输中出现的操作失败、错误、遗漏、中断、缺陷、延迟，计算机病毒，断线或系统运行失败。<br />
    (二)我们可以在不事先通知的情况下更改信息,并且不承担更新这些信息的义务。不经任何种类的授权，不做任何专门或暗指或法定的不侵犯第三方权利、名称、可出售性、出于某种特殊目的适当措施或不携带计算机病毒的保证。<br />
    (三)我们不对您查询信息内容的正确性、适当性、完整性、准确性、可靠性或适时性做出任何证明、声明和保证。我们不对任何因个人平台产生的错误、遗漏及失准承担任何责任。<br />
    (四)
    对于由于您违反本协议导致任何第三方针对我们及或我们的员工提出的任何申诉、起诉、要求或者诉讼或者其他法律程序，您同意自费作出赔偿并令其免受上述损害。
    <br />
    <br />
    第八条 附则<br />
    (一)本协议的某一条款被确认无效，均不影响本协议其他条款的效力。<br />
    (二)
    本协议未尽事宜，根据我国相关法律、法规及我们相关业务规定办理。如需制定补充协议，其法律效力同本协议。<br />
    <br />
    本协议通过点击同意/勾选的方式签署，自签署之日生效。<br />
    本协议于 {{ dateValue }}生效。
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

const dateValue = ref()

onMounted(() => {
  const date = new Date() // 获取当前日期和时间
  const year = date.getFullYear() // 获取年份
  const month = date.getMonth() + 1 // 获取月份（注意：月份从0开始，需要加1）
  const day = date.getDate() // 获取日期
  dateValue.value = year + '年' + month + '月' + day + '日'
})
</script>

<style scoped>
</style>