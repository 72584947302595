<template>
  <div style="width: 100%;height: 100%">
    <div class="index">
      <div class="header-bottom">
        <div class="max-width">
          <!-- 导航左边 -->
          <div style="text-align: center;display: flex">
            <img src="../assets/favicon.png" style="margin-right: 20px"><span class="logo-zh">星朗乐科技</span>
          </div>
          <!-- 导航右边 -->
          <ul class="nav">
            <li class="nav-box" @click="goAnchor('#bg5')">应用场景</li>
            <li class="nav-box" @click="goAnchor('#bg4')">产业优势</li>
            <li class="nav-box" @click="goAnchor('#bg7')">应用下载</li>
            <li class="nav-box" @click="goAnchor('#bg8')">关于我们</li>
          </ul>
        </div>
      </div>
    </div>
    <img id="bg2" src="../assets/bg2.png" style="width: 100%" alt/>
    <img id="bg3" src="../assets/bg3.png" style="width: 100%" alt/>
    <img id="bg4" src="../assets/bg4.png" style="width: 100%" alt/>
    <img id="bg5" src="../assets/bg5.png" style="width: 100%" alt/>
    <img id="bg6" src="../assets/bg6.png" style="width: 100%" alt/>
    <img id="bg7" src="../assets/bg7.png" style="width: 100%" alt/>

    <div id="bg8" style="padding-top: 50px;background-color: #F5F5F5">
      <div style="margin-right: 40%;margin-left: 40%">
        <div style="display: flex; width: 100%; justify-content: space-between;">
          <RouterLink to="/about">
            <span style="display: table-cell;text-align: center;font-size: 1rem;color: #3d3d3d;">关于我们</span>
          </RouterLink>
          <RouterLink to="/privacyPolicy">
            <span style="display: table-cell;text-align: center;font-size: 1rem;color: #3d3d3d;">隐私政策</span>
          </RouterLink>
          <RouterLink to="/agreement">
            <span style="display: table-cell;text-align: center;font-size: 1rem;color: #3d3d3d;">服务协议</span>
          </RouterLink>
        </div>
      </div>
    </div>
    <div style="display: flex; width: 100%;background-color: #F5F5F5">
      <span style="color: #666666;  margin-right: auto; margin-left: auto;  font-size: 1rem;  margin-top: 0.7rem;">
          成都星朗乐科技有限公司&emsp;&emsp;&emsp;&emsp;<a
          href="https://beian.miit.gov.cn">备案号：蜀ICP备2024087738号-1</a></span>
    </div>
    <div style="display: flex; width: 100%;background-color: #F5F5F5">
      <span
          style="
          color: #666666;
          margin-right: auto;
          margin-left: auto;
          font-size: 1rem;
          margin-top: 0.7rem;
        "
      >公司地址：四川省成都市武侯区武兴二路8号1栋9层1-0-4号附911号</span
      >
    </div>
    <div style="display: flex; width: 100%; padding-bottom: 20px;background-color: #F5F5F5">
      <span style="color: #666666;margin-right: auto;margin-left: auto;font-size: 1rem;margin-top: 0.7rem;">
          联系电话：15600791073</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  methods: {
    //锚点跳转
    goAnchor(selector) {/*参数selector是id选择器（#anchor14）*/
      document.querySelector(selector).scrollIntoView({
        behavior: "smooth"
      });
    },
  }
}
</script>

<style scoped>
.index {
  background: url("../assets/bg1.png");
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}

.header-bottom {
  margin: 0 auto;
  color: #fff;
  background: #00000000;
  position: relative;
  z-index: 11
}

.max-width {
  width: 85%;
  margin: 0 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center
}

.logo-zh {
  font-size: 26px;
  font-weight: 700;
  color: #1A1A1A;
  letter-spacing: 8px
}

.nav {
  display: -ms-flexbox;
  display: flex;
  position: relative
}

.nav > li {
  width: 180px;
  text-align: center;
  height: 184px;
  line-height: 184px;
  cursor: pointer;
  display: block;
  box-sizing: border-box;
  color: #1A1A1A;
  font-weight: bold;
  font-size: 25px;
  text-decoration: none
}

</style>