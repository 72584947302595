<script setup>
import { ref, onMounted, onUnmounted } from 'vue'

onMounted(() => {
})

onUnmounted(() => {
  // 组件卸载时的清理工作
})
</script>

<template>
  <div class="about_view">
    <div class="about_logo">
      <img src="../assets/about_us.png" alt="" />
      <span>牧星查</span>
    </div>
    <div class="about_content">
      牧星查是一款专为企业、及个人服务的个人线上线下多场景大数据报告查询功能，通过智能运算技术整合了权威合法数据机构的信息，以报告形势呈现给用户；<br/>
      同时牧星查推出全民分销模式，不管是机构还是个人，都可以通过平台的'推广'功能、'邀请'功能来赚取高额佣金及分润。<br/>
      牧星查的运用场景非常广泛，如：中介、家政、婚恋等需要个人行为背调的机构和企业。<br/><br/>
      企业用-围绕公司业务推广报告，员工推广增业绩，老板赚分佣。<br/>
      个人用-日常生活场景个人行为背调，自推广增加个人收益。
    </div>
  </div>
</template>

<style lang="less" scoped>
.about_view {
  height: 100%;
  overflow: hidden;
  .about_logo {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 7rem;
      margin-bottom: 2rem;
    }
    span {
      font-weight: 400;
      font-size: 1.5rem;
      color: #62d5b8;
    }
  }
  .about_content {
    padding: 0 34px;
    font-weight: 400;
    font-size: 1rem;
    color: #3d3d3d;
    line-height: 1.5rem;
  }
}
</style>